<template>
<section class="domain_content">
    <h1 class="center">
        GBWhatsApp Latest Version APK Download Links
    </h1>

    <div class="logo-table">
      <p>
        <img alt="gbwhatsapp logo" class="wi" src="@/assets/shiny-logo.webp">
      </p>
      <table cellspacing="0">
          <tr>
              <th>App Name</th>
              <th>GBWhatsApp</th>
          </tr>
          <tr>
              <td>Version</td>
              <td>18.10</td>
          </tr>
          <tr>
              <td>Size</td>
              <td>77M</td>
          </tr>
          <tr>
              <td>Total Downloads</td>
              <td>2,000,000+</td>
          </tr>
          <tr>
              <td>Last Updated</td>
              <td>1 days ago</td>
          </tr>
      </table>
    </div>

    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="download" class="download-img">
        <div class="download-name">
            Direct Download
        </div>
    </div>
</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';

export default {
    ...{
        "metaInfo": {
            "title": "GB WhatsApp APK Download Page with Anti-Ban Enhanced",
            "meta": [{
                    "name": "description",
                    "content": "Download GB WhatsApp APK (Anti-Ban) New Version for Android"
                }, {
                    "name": "title",
                    "content": "GB WhatsApp APK Download Page with Anti-Ban Enhanced"
                }, {
                    "name": "keywords",
                    "content": "gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download"
                },
                {
                    "property": "og:title",
                    "content": "GB WhatsApp APK Download Page with Anti-Ban Enhanced"
                }, {
                    "property": "og:description",
                    "content": "Download GB WhatsApp APK (Anti-Ban) New Version for Android"
                }
            ],
            "link": [{
                "rel": "canonical",
                "href": "https://gbapks.org.in/gbwhatsapp-update/"
            }]
        }
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },
        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
